import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import '../../assets/css/main.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import CircularProgressBar from 'ui-component/CircularProgressBar';
import HistoryList from './HistoryList';
import TransactionHistory from './TransactionHistory';
import getAxiosInstance from 'api';
import moment from 'moment';
import toast from 'react-hot-toast';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: '24px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.06)',
  borderRadius: '12px',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

const DateRangeButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#000',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

const Calls = () => {
  const theme = useTheme();
  const [callData, setCallData] = useState([]);
  const [productsDate, setProductsDate] = useState({
    0: moment().subtract(1, 'months')._d,
    1: moment()._d,
  });
  const [callsDate, setCallsDate] = useState({
    0: moment().subtract(1, 'months')._d,
    1: moment()._d,
  });
  const [customerDate, setCustomerDate] = useState({
    0: moment().subtract(1, 'months')._d,
    1: moment()._d,
  });
  const [productData, setProductData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [loadingCalls, setLoadingCalls] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  useEffect(() => {
    fetchCallsCount();
  }, [callsDate]);
  useEffect(() => {
    fetchProductCount();
  }, [productsDate]);
  useEffect(() => {
    fetchCustomerCount();
  }, [customerDate]);
  const fetchCallsCount = () => {
    setLoadingCalls(true);
    getAxiosInstance(
      `adminOrchestrator/dashboard/getActiveProductCounts`,
      'post',
      {
        startDate: moment(callsDate[0]).format('MM-DD-YYYY'),
        endDate: moment(callsDate[1]).format('MM-DD-YYYY'),
      },
      { 'journey-type': 'Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          setLoadingCalls(false);
          setCallData([
            {
              title: 'Total',
              value: res.data.data.totalCount,
              progress:
                (res.data.data.totalCount / res.data.data.totalCount) * 100 ||
                0,
              isDrillDown: false,
            },
            {
              title: 'API',
              value: res.data.data.apiCount,
              progress:
                (res.data.data.apiCount / res.data.data.totalCount) * 100 || 0,
              isDrillDown: true,
              route: 'api',
            },
            {
              title: 'Dashboard',
              value: res.data.data.onlineCount,
              progress:
                (res.data.data.onlineCount / res.data.data.totalCount) * 100 ||
                0,
              isDrillDown: true,
              route: 'dashboard',
            },
          ]);
        }
      })
      .catch((err) => {
        setLoadingCalls(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const fetchProductCount = () => {
    setLoadingProducts(true);
    getAxiosInstance(
      `adminOrchestrator/dashboard/getConsentCountByDateRange`,
      'post',
      {
        startDate: moment(productsDate[0]).format('MM-DD-YYYY'),
        endDate: moment(productsDate[1]).format('MM-DD-YYYY'),
      },
      { 'journey-type': 'Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          setLoadingProducts(false);
          setProductData([
            {
              title: 'Credit Assessment',
              value: res.data.data.creditRiskCount,
              progress: 100,
              route: 'credit-assessment',
              isDrillDown: true,
            },
            {
              title: 'Direct Debit',
              value: res.data.data.directDebitCount,
              progress: 100,
              route: 'direct-debit',
              isDrillDown: true,
            },
            {
              title: 'Income Verification',
              value: res.data.data.incomeVerificationCount,
              progress: 100,
              route: 'income-verification',
              isDrillDown: true,
            },
            {
              title: 'BVN',
              value: res.data.data.bvnCount,
              progress: 100,
              route: 'bvn',
              isDrillDown: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoadingProducts(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const fetchCustomerCount = () => {
    setLoadingCustomers(true);
    getAxiosInstance(
      `adminOrchestrator/dashboard/getBpAndUsersCount`,
      'post',
      {
        startDate: moment(customerDate[0]).format('MM-DD-YYYY'),
        endDate: moment(customerDate[1]).format('MM-DD-YYYY'),
      },
      { 'journey-type': 'Dashboard' }
    )
      .then((res) => {
        if (res?.data?.codes === 200) {
          setLoadingCustomers(false);
          setCustomerData([
            {
              title: 'Total User',
              value: res.data.data.userCount,
              progress: 100,
              subtitle: 'Total',
            },
            {
              title: 'API Customers',
              value: res.data.data.apiBPCount,
              progress:
                (res.data.data.apiBPCount / res.data.data.totalCount) * 100 ||
                0,
              subtitle: 'API',
            },
            {
              title: 'Dashboard Customers',
              value: res.data.data.onlineBPCount,
              progress:
                (res.data.data.onlineBPCount / res.data.data.totalCount) *
                  100 || 0,
              subtitle: 'Dashboard',
            },
            {
              title: 'Total Customer',
              value: res.data.data.totalCount,
              progress:
                (res.data.data.totalCount / res.data.data.totalCount) * 100 ||
                0,
              subtitle: 'Total',
            },
          ]);
        }
      })
      .catch((err) => {
        setLoadingCustomers(false);
        toast.error(err?.response?.data?.message);
      });
  };
  const navigate = useNavigate();

  const handleViewDrilldown = (route) => {
    navigate(`/calls/${route}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box mt={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 500, color: '#212120' }}>
            Calls
          </Typography>
          <DateRangePicker
            appearance="default"
            placeholder="Select Date Range"
            value={callsDate}
            onChange={(value) => {
              setCallsDate({
                0: value[0],
                1: value[1],
              });
            }}
            showOneCalendar
          />
        </Box>
        <StyledCard>
          {loadingCalls ? (
            <Box display="flex" justifyContent="center" py={5}>
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Grid container spacing={4} justifyContent="center">
              {callData?.length &&
                callData.map((call, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    key={index}
                    sx={{ textAlign: 'center' }}
                  >
                    <Box sx={{ maxWidth: 200, margin: '0 auto' }}>
                      <CircularProgressBar
                        width={100}
                        size={180}
                        title={call.title}
                        value={call.value}
                        progress={call.progress}
                      />
                      {call?.isDrillDown && (
                        <Button
                          variant="text"
                          onClick={() => handleViewDrilldown(call.route)}
                          startIcon={<KeyboardArrowUpIcon />}
                          sx={{
                            color: theme.palette.secondary.main,
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: 400,
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          View Drilldown
                        </Button>
                      )}
                    </Box>
                  </Grid>
                ))}
            </Grid>
          )}
        </StyledCard>
      </Box>
      <Box mt={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 500, color: '#212120' }}>
            Products
          </Typography>
          <DateRangePicker
            appearance="default"
            placeholder="Select Date Range"
            value={productsDate}
            onChange={(value) => {
              console.log('value', value);
              setProductsDate({
                0: value[0],
                1: value[1],
              });
            }}
            showOneCalendar
          />
        </Box>
        <StyledCard>
          {loadingProducts ? (
            <Box display="flex" justifyContent="center" py={5}>
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Grid container spacing={4} justifyContent="center">
              {productData.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  key={index}
                  sx={{ textAlign: 'center' }}
                >
                  <Box>
                    <CircularProgressBar
                      width={100}
                      size={180}
                      title={'Total calls'}
                      value={item?.value}
                      progress={item?.progress}
                      header={item?.title}
                    />
                    {item?.isDrillDown && (
                      <Button
                        variant="text"
                        onClick={() => handleViewDrilldown(item.route)}
                        startIcon={<KeyboardArrowUpIcon />}
                        sx={{
                          color: '#FC5719',
                          textTransform: 'none',
                          fontSize: '14px',
                          fontWeight: 400,
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        View Drilldown
                      </Button>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </StyledCard>
      </Box>
      <Box mt={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 500, color: '#212120' }}>
            Customers
          </Typography>
          <DateRangePicker
            appearance="default"
            placeholder="Select Date Range"
            value={callsDate}
            onChange={(value) => {
              setCustomerDate({
                0: value[0],
                1: value[1],
              });
            }}
            showOneCalendar
          />
        </Box>
        <StyledCard>
          {loadingCustomers ? (
            <Box display="flex" justifyContent="center" py={5}>
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Grid container spacing={4} justifyContent="center">
              {customerData.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  key={index}
                  sx={{ textAlign: 'center' }}
                >
                  <Box sx={{ maxWidth: 200, margin: '0 auto' }}>
                    <CircularProgressBar
                      width={100}
                      size={180}
                      title={item?.subtitle}
                      value={item?.value}
                      progress={item?.progress}
                      header={item?.title}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </StyledCard>
      </Box>
      <HistoryList />
      <TransactionHistory />
    </Box>
  );
};
export default Calls;
